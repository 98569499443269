import React from "react"
const strftime = require("strftime")

interface Props {
    format: string,
    zone: string,
    value: string,
}

export class Time extends React.Component<Props, {}> {
    props: Props;

    time() {
        return new Date(Date.parse(this.props.value))
    }

    converted_time() {
        return new Date(this.time().toLocaleString("en-US", { timeZone: this.props.zone }));
    }

    formatted_time() {
        if (this.props.value) {
            return strftime(this.props.format, this.converted_time());
        } else {
            return <span>unknown</span>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <span className={"info info-time"}>
                    {this.formatted_time()}
                </span>
            </React.Fragment>
        );
    }
}

export default Time
