import React from "react"
import {ConditionsData, ForecastData, PanelData} from "./Panel";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
}

export class Title extends React.Component<Props, {}> {
    props: Props;

    render () {
        if (!this.props.panel)
            return <React.Fragment/>;

        return (
            <React.Fragment>
                <tr className="info" style={{height: "5%"}}>
                    <td className="info title" style={{width: "100%", color: "#333333"}} colSpan={5}>
                        {this.props.panel.location}
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default Title
