import React from "react"
import {ActionCableConsumer} from 'react-actioncable-provider';

interface Props {
    slug: string,
    onReceived: any,
}

export class PanelChannel extends React.Component<Props> {
    props: Props;

    render () {
        return <ActionCableConsumer channel={{channel: "PanelChannel", slug: this.props.slug}} onReceived={this.props.onReceived}/>;
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.slug !== nextProps.slug;
    }
}

export default PanelChannel
