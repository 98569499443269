import React from "react"
import Cell from "./Cell";
import DailyForecast from "./DailyForecast";
import {ConditionsData, ForecastData, LiveWeatherData, PanelData} from "./Panel";
import AirQuality from "./AirQuality";
import Temperature from "./Temperature";
import Humidity from "./Humidity";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    live_weather: LiveWeatherData,
}

export class Forecast extends React.Component<Props, {}> {
    props: Props;

    live_weather_stale() {
        return (Date.now() - Date.parse(this.props.live_weather.time)) > 60000;
    }

    render_forecast_current_conditions() {
        if (!this.props.conditions)
            return <React.Fragment/>;

        return (
            <Cell size={"l"} label={null}>
                <div className="icon-l weather-icon">{this.props.conditions.meteocons_icon}</div>
                <div className="info-s" style={{marginTop: "1rem"}}>{this.props.conditions.description}</div>
            </Cell>
        );
    }

    render_daily_forecast() {
        if (!this.props.forecast)
            return <React.Fragment/>;

        return (
            <table width="100%">
                <tbody>
                <tr>
                    {
                        this.props.forecast.daily_forecast.map((forecast, index) => {
                            return (
                                <td key={forecast.time}>
                                    <DailyForecast panel={this.props.panel} forecast={forecast}/>
                                </td>
                            )
                        })
                    }
                </tr>
                </tbody>
            </table>
        );
    }

    render_indoor_temperature() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <React.Fragment>
                    <Cell size={"m"} label={"indoor temperature"}>
                        <Temperature value={this.props.live_weather.indoor_temperature} unit={this.props.panel.units.temperature} display_unit={"symbol"}/>
                    </Cell>
                </React.Fragment>
            );
        }

        return <React.Fragment/>;
    }

    render_indoor_humidity() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <React.Fragment>
                    <Cell size={"m"} label={"indoor humidity"}>
                        <Humidity value={this.props.live_weather.indoor_humidity} display_unit={"small"}/>
                    </Cell>
                </React.Fragment>
            );
        }

        return <React.Fragment/>;
    }

    render_indoor_temperature_and_humidity() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <React.Fragment>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td>{this.render_indoor_temperature()}</td>
                            <td>{this.render_indoor_humidity()}</td>
                        </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }

        return <React.Fragment/>;
    }

    render_indoor_air_quality() {
        if (this.props.live_weather !== null && this.props.live_weather.indoor_air_quality !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"s"} label={"indoor air quality"}>
                    <AirQuality air_quality={this.props.live_weather.indoor_air_quality}/>
                </Cell>
            );
        }

        return <React.Fragment/>;
    }

    render () {
        return (
            <React.Fragment>
                <tr className="info" style={{height: "37%"}}>
                    <td className="info" style={{width: "20%"}}>
                        {this.render_forecast_current_conditions()}
                    </td>
                    <td className="info" style={{width: "60%"}} colSpan={3}>
                        {this.render_daily_forecast()}
                    </td>
                    <td className="info" width="20%">
                        {this.render_indoor_temperature_and_humidity()}
                        <div style={{paddingBottom: '1rem'}}/>
                        {this.render_indoor_air_quality()}
                    </td>
                </tr>

            </React.Fragment>
        );
    }
}

export default Forecast
