import React from "react"
import {air_quality} from "./AirQuality";

interface Props {
    value: number,
    pm_size: string,
    display_unit: string,
}

export class ParticulateMatter extends React.Component<Props, {}> {
    props: Props;

    unit_formatter() {
        return new Intl.NumberFormat("en", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    }

    formatter = this.unit_formatter();

    formatted_unit() {
        switch (this.props.display_unit) {
            case "none":
                return <span/>;
            case "small":
                return <span style={{fontSize: "50%", verticalAlign: "30%"}}>&nbsp;PM<sub>{this.props.pm_size}</sub></span>;
            default:
            case "normal":
                return <span>PM<sub>&nbsp;{this.props.pm_size}</sub></span>;
        }
    }

    formatted_particulate_matter() {
        let aq = air_quality(this.props.pm_size, this.props.value)

        return (
            <span style={{color: aq.color}}>
                {this.formatter.format(this.props.value)}
                {this.formatted_unit()}
            </span>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className={"info info-particulate-matter"}>
                    {this.formatted_particulate_matter()}
                </div>
            </React.Fragment>
        );
    }
}

export default ParticulateMatter
