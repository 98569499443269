import React from "react"
import Temperature from "./Temperature";
import Cell from "./Cell";
import Pressure from "./Pressure";
import Humidity from "./Humidity";
import Time from "./Time";
import {ConditionsData, ForecastData, LiveWeatherData, PanelData} from "./Panel";
import Speed from "./Speed";
import CompassDirection from "./CompassDirection";
import AirQuality from "./AirQuality";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    live_weather: LiveWeatherData,
}

class Conditions extends React.Component<Props, {}> {
    props: Props;

    live_weather_stale() {
        return (Date.now() - Date.parse(this.props.live_weather.time)) > 60000;
    }

    render_temperature() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"l"} label={"temperature"}>
                    <Temperature value={this.props.live_weather.temperature} unit={this.props.panel.units.temperature} display_unit={"small"}/>
                </Cell>
            );
        }

        return (
            <Cell size={"l"} label={"temperature"}>
                <Temperature value={this.props.conditions.temperature} unit={this.props.panel.units.temperature} display_unit={"small"}/>
            </Cell>
        );
    }

    render_humidity() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"m"} label={"humidity"}>
                    <Humidity value={this.props.live_weather.humidity} display_unit={"small"}/>
                </Cell>
            )
        }

        return (
            <Cell size={"m"} label={"humidity"}>
                <Humidity value={this.props.conditions.humidity} display_unit={"small"}/>
            </Cell>
        )
    }

    render_dew_point() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"m"} label={"dew point"}>
                    <Temperature value={this.props.live_weather.dew_point} unit={this.props.panel.units.temperature} display_unit={"symbol"}/>
                </Cell>
            )
        }

        return <React.Fragment/>;
    }

    render_wind_direction() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"m"} label={"wind direction"}>
                    <CompassDirection value={this.props.live_weather.wind_direction}/>
                </Cell>
            )
        }

        return (
            <Cell size={"m"} label={"wind direction"}>
                <CompassDirection value={this.props.conditions.wind_direction}/>
            </Cell>
        )
    }

    render_wind_speed() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"m"} label={"wind speed"}>
                    <Speed value={this.props.live_weather.wind_speed} max_value={this.props.live_weather.wind_speed_10_minutes_max} unit={this.props.panel.units.speed} display_unit={"small"}/>
                </Cell>
            )
        }

        return (
            <Cell size={"m"} label={"wind speed"}>
                <Speed value={this.props.conditions.wind_speed} unit={this.props.panel.units.speed} display_unit={"small"}/>
            </Cell>
        )
    }

    render_pressure() {
        if (this.props.live_weather !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"m"} label={"pressure"}>
                    <Pressure value={this.props.live_weather.pressure} trend={this.props.live_weather.pressure_trend} unit={this.props.panel.units.pressure} display_unit={"small"}/>
                </Cell>
            )
        }

        return (
            <Cell size={"m"} label={"pressure"}>
                <Pressure value={this.props.conditions.pressure} trend={null} unit={this.props.panel.units.pressure} display_unit={"small"}/>
            </Cell>
        )
    }

    render_outdoor_air_quality() {
        if (this.props.live_weather !== null && this.props.live_weather.air_quality !== null && !this.live_weather_stale()) {
            return (
                <Cell size={"s"} label={"outdoor air quality"}>
                    <AirQuality air_quality={this.props.live_weather.air_quality}/>
                </Cell>
            );
        }

        return <React.Fragment/>;
    }

    render_sunrise() {
        return (
            <Cell size={"m"} label={"sunrise"}>
                <Time format={this.props.panel.times.format} zone={this.props.panel.times.zone} value={this.props.conditions.sunrise}/>
            </Cell>
        );
    }

    render_sunset() {
        return (
            <Cell size={"m"} label={"sunset"}>
                <Time format={this.props.panel.times.format} zone={this.props.panel.times.zone} value={this.props.conditions.sunset}/>
            </Cell>
        );
    }

    render () {
        if (this.props.conditions === undefined || this.props.forecast === undefined) {
            return <React.Fragment/>;
        }

        return (
            <React.Fragment>
                <tr className="info" style={{height: '30%'}}>
                    <td className="info" width="20%">
                        {this.render_temperature()}
                    </td>
                    <td className="info" style={{width: '20%'}}>
                        {this.render_humidity()}
                        <div style={{paddingBottom: '1rem'}}/>
                        {this.render_dew_point()}
                    </td>
                    <td className="info" style={{width: '20%'}}>
                        {this.render_wind_direction()}
                        <div style={{paddingBottom: '1rem'}}/>
                        {this.render_wind_speed()}
                    </td>
                    <td className="info" style={{width: '20%'}}>
                        {this.render_pressure()}
                        <div style={{paddingBottom: '1rem'}}/>
                        {this.render_outdoor_air_quality()}
                    </td>
                    <td className="info" style={{width: '20%'}}>
                        {this.render_sunrise()}
                        <div style={{paddingBottom: '1rem'}}/>
                        {this.render_sunset()}
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default Conditions
