import React from "react"
import {ExclamationTriangleFill} from "react-bootstrap-icons";
import Time from "./Time";
import {AlertData, PanelData} from "./Panel";

interface Props {
    panel: PanelData,
    alert: AlertData,
    index: number,
    total: number,
}

export class Alert extends React.Component<Props, {}> {
    props: Props;

    id() {
        return "alert-" + this.props.index;
    }

    render() {
        return (
            <React.Fragment>
                <div className={"pb-8"} id={this.id()}>
                    <div style={{fontSize: "3.5vw"}} className={"pb-4"}>
                        <a href={"#alerts-index"}>
                            <ExclamationTriangleFill/> {this.props.alert.event} <ExclamationTriangleFill/>
                        </a>
                    </div>
                    <div style={{fontSize: "1.5vw"}} className={"pb-4"}>
                        {this.props.alert.severity} – {this.props.alert.urgency} – {this.props.alert.certainty}
                    </div>
                    <div style={{fontSize: "1.5vw"}} className={"pb-4"}>
                        <Time format={"%F %T"} zone={this.props.panel.times.zone} value={this.props.alert.onset}/> until <Time format={"%F %T"} zone={this.props.panel.times.zone} value={this.props.alert.ends}/>
                    </div>
                    <div style={{fontSize: "1.5vw"}} className={"pb-4"}>
                        {this.props.alert.instruction}
                    </div>
                    <div style={{fontSize: "2.5vw"}} className={"pb-4"}>
                        • • • • • •
                    </div>
                    <pre style={{color: "white", fontSize: "1.5vw", textAlign: "left"}}>
                    {this.props.alert.description}
                </pre>
                </div>
            </React.Fragment>
        );
    }
}

export default Alert
