import React from "react"

interface Props {
    value: number,
    display_unit?: string,
}

export class Humidity extends React.Component<Props, {}> {
    props: Props;

    unit_formatter() {
        return new Intl.NumberFormat("en", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    }

    formatter = this.unit_formatter();

    formatted_unit() {
        switch (this.props.display_unit) {
            case "none":
                return <span/>;
            case "small":
                return <span style={{fontSize: "50%", verticalAlign: "30%"}}>%</span>;
            default:
            case "normal":
                return <span>%</span>;
        }
    }

    formatted_humidity() {
        return <span>{this.formatter.format(this.props.value)}{this.formatted_unit()}</span>;
    }

    render() {
        return (
            <React.Fragment>
                <div className={"info info-humidity"}>
                    {this.formatted_humidity()}
                </div>
            </React.Fragment>
        );
    }
}

export default Humidity
