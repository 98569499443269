import {AlertsData, ConditionsData, ForecastData, LiveWeatherData, PanelData} from "./Panel";
import React from "react";
import Title from "./Title";
import Conditions from "./Conditions";
import Summary from "./Summary";
import Forecast from "./Forecast";
import Footnote from "./Footnote";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    alerts: AlertsData,
    live_weather: LiveWeatherData,
}

class DefaultPage extends React.Component<Props, {}> {
    props: Props;

     render() {
        return (
            <React.Fragment>
                <table className="info" style={{height: '95%'}}>
                    <tbody>
                    <Title changePage={this.props.changePage} panel={this.props.panel}/>
                    <Conditions changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} live_weather={this.props.live_weather}/>
                    <Summary changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} alerts={this.props.alerts}/>
                    <Forecast changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} live_weather={this.props.live_weather}/>
                    </tbody>
                </table>
                <Footnote changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} alerts={this.props.alerts} live_weather={this.props.live_weather}/>
            </React.Fragment>
        );
    }
}

export default DefaultPage
