import React from "react"
import Cell from "./Cell";
import {AlertsData, ConditionsData, ForecastData, PanelData} from "./Panel";
import {ExclamationTriangleFill} from "react-bootstrap-icons";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    alerts: AlertsData,
}

export class Summary extends React.Component<Props, {}> {
    props: Props;

    render_alerts() {
        if (this.props.alerts == null || this.props.alerts.alerts == null || this.props.alerts.alerts.length == 0)
            return <React.Fragment/>;

        return (
            <React.Fragment>
                <div style={{color: "#ff0000"}} onClick={() => this.props.changePage("alerts", null)}>
                    <ExclamationTriangleFill/>
                    <span style={{fontSize: "50%", fontWeight: "bold"}} className={"ps-3"}>
                        {this.props.alerts.alerts.length}
                    </span>
                </div>
            </React.Fragment>
        )
    }

    render () {
        if (!this.props.forecast)
            return <React.Fragment/>;

        return (
            <React.Fragment>
                <tr className="info" style={{}}>
                    <td className="info">
                        <Cell size={"m"} label={""}>
                            {this.render_alerts()}
                        </Cell>
                    </td>
                    <td className="info" width="100%" colSpan={3} onClick={() => this.props.changePage("afd", null)}>
                        <Cell size={"s"} label={null}>
                            {this.props.forecast.summary}
                        </Cell>
                    </td>
                    <td/>
                </tr>
            </React.Fragment>
        );
    }
}

export default Summary
