import React from "react"
import Time from "./Time";
import Temperature from "./Temperature";
import WeatherIcon from "./WeatherIcon";
import {DailyForecastData, PanelData} from "./Panel";
import MoonPhaseIcon from "./MoonPhaseIcon";

interface Props {
    panel: PanelData,
    forecast: DailyForecastData,
}

export class DailyForecast extends React.Component<Props, {}> {
    props: Props;

    render() {
        return (
            <React.Fragment>
                <div className={"daily-forecast"}>
                    <div className="info-s day-of-week"><Time format={"%a"} zone={this.props.panel.times.zone} value={this.props.forecast.time}/></div>
                    <div className="icon-m"><WeatherIcon icon={this.props.forecast.meteocons_icon}/></div>
                    <div className="info-xs"><Temperature value={this.props.forecast.temperature_max} unit={this.props.panel.units.temperature} display_unit={"symbol"}/></div>
                    <div className="info-xs"><Temperature value={this.props.forecast.temperature_min} unit={this.props.panel.units.temperature} display_unit={"symbol"}/></div>
                    <div className="info-s moon-phase-icon"><MoonPhaseIcon value={this.props.forecast.moon_phase}/></div>
                </div>
            </React.Fragment>
        );
    }
}

export default DailyForecast
