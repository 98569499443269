import React from "react"
import {ActionCableProvider} from 'react-actioncable-provider';
import {createConsumer} from "@rails/actioncable"

interface Props {
    children: any,
}

export class Channels extends React.Component<Props> {
    props: Props;

    cable = createConsumer();

    render () {
        return (
            <ActionCableProvider cable={this.cable}>
                {this.props.children}
            </ActionCableProvider>
        );
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
        return false;
    }
}

export default Channels
