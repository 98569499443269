import React from "react";

interface Props {
}

class NoDataPage extends React.Component<Props, {}> {
    props: Props;

    render() {
        return (
            <React.Fragment>
                <table className="info" style={{height: '95%'}}>
                    <tbody>
                    <tr style={{height: "100%"}}>
                        <td style={{width: "100%", textAlign: "center"}}>
                            <div className={"no-data"}>
                                No data for this panel is available at this time.
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default NoDataPage
