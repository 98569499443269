import {AlertsData, ConditionsData, ForecastData, LiveWeatherData, PanelData} from "./Panel";
import React from "react";
import NavigablePage from "./NavigablePage";
import AlertsList from "./AlertsList";
import AlertsIndex from "./AlertsIndex";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    alerts: AlertsData,
    live_weather: LiveWeatherData,
}

class AlertsPage extends React.Component<Props, {}> {
    props: Props;

     render() {
        return (
            <React.Fragment>
                <NavigablePage changePage={this.props.changePage}>
                    <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                        <AlertsIndex panel={this.props.panel} alerts={this.props.alerts}/>
                        <AlertsList panel={this.props.panel} alerts={this.props.alerts}/>
                    </div>
                </NavigablePage>
            </React.Fragment>
        );
    }
}

export default AlertsPage
