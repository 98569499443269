import React from "react"
import {AlertsData, ConditionsData, ForecastData, LiveWeatherData, PanelData} from "./Panel";
import Time from "./Time";
import {CloudCheckFill, CloudSlashFill, Dot, LightningFill, XOctagonFill} from "react-bootstrap-icons";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    alerts: AlertsData,
    live_weather: LiveWeatherData,
}

export class Footnote extends React.Component<Props, {}> {
    props: Props;

    time_format = "%F %T";

    conditions_stale() {
        return (Date.now() - Date.parse(this.props.conditions.time)) > (60 * 60 * 1000);
    }

    conditions_time() {
        return (
            <React.Fragment>
                Conditions: <span className={"footnote-time"}><Time format={this.time_format} zone={this.props.panel.times.zone} value={this.props.conditions.time}/></span>
            </React.Fragment>
        );
    }

    render_conditions_footnote() {
        if (!this.props.conditions)
            return <span>unknown</span>;

        if (this.conditions_stale()) {
            return (
                <React.Fragment>
                    <span style={{color: "darkred"}}>
                        <CloudSlashFill/> {this.conditions_time()}
                    </span>
                </React.Fragment>
            );

        }

        return (
            <React.Fragment>
                <CloudCheckFill/> {this.conditions_time()}
            </React.Fragment>
        );
    }

    forecast_stale() {
        return (Date.now() - Date.parse(this.props.conditions.time)) > (18 * 60 * 60 * 1000);
    }

    forecast_time_range() {
        return (
            <React.Fragment>
                Forecast: <span className={"footnote-time"}><Time format={this.time_format} zone={this.props.panel.times.zone} value={this.props.forecast.time_min}/></span> – <span className={"footnote-time"}><Time format={this.time_format} zone={this.props.panel.times.zone} value={this.props.forecast.time_max}/></span>
            </React.Fragment>
        );
    }

    render_forecast_footnote() {
        if (!this.props.forecast)
            return <span>unknown</span>;

        if (this.forecast_stale()) {
            return (
                <React.Fragment>
                    <span style={{color: "darkred"}}>
                        <Dot/> <CloudSlashFill/> {this.forecast_time_range()}
                    </span>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Dot/> <CloudCheckFill/> {this.forecast_time_range()}
            </React.Fragment>
        );
    }

    alerts_stale() {
        return (Date.now() - Date.parse(this.props.alerts.time)) > (10 * 60 * 1000);
    }

    alerts_time() {
        return (
            <React.Fragment>
                Alerts: <span className={"footnote-time"}><Time format={this.time_format} zone={this.props.panel.times.zone} value={this.props.alerts.time}/></span>
            </React.Fragment>
        );
    }

    render_alerts_footnote() {
        if (!this.props.alerts)
            return <React.Fragment/>;

        if (this.alerts_stale()) {
            return (
                <React.Fragment>
                    <span style={{color: "darkred"}}>
                        <Dot/> <CloudSlashFill/> {this.alerts_time()}
                    </span>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Dot/> <CloudCheckFill/> {this.alerts_time()}
            </React.Fragment>
        );
    }

    live_weather_stale() {
        return (Date.now() - Date.parse(this.props.live_weather.time)) > 60000;
    }

    live_weather_time() {
        return (
            <React.Fragment>
                Live: <span className={"footnote-time"}><Time format={"%T"} zone={this.props.panel.times.zone} value={this.props.live_weather.time}/></span>
            </React.Fragment>
        )
    }

    render_live_weather_footnote() {
        if (!this.props.live_weather)
            return <React.Fragment/>;

        if (this.live_weather_stale()) {
            return (
                <React.Fragment>
                    <Dot/> <span style={{color: "red"}}>
                    <XOctagonFill/> {this.live_weather_time()} (stale)
                    </span>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Dot/> <span style={{color: "green"}}>
                   <LightningFill/> {this.live_weather_time()}
                </span>
            </React.Fragment>
        );
    }

    render () {
        return (
            <React.Fragment>
                <div className="footnote">
                    {this.render_conditions_footnote()} {this.render_forecast_footnote()} {this.render_alerts_footnote()} {this.render_live_weather_footnote()}
                </div>
            </React.Fragment>
        );
    }
}

export default Footnote
