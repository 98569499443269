import React from "react"

interface Props {
    value: number,
}

const moon_phase_icons: Array<string> = [
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "*",
    "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0"
];

export class MoonPhaseIcon extends React.Component<Props, {}> {
    props: Props;

    moon_phase_icon() {
        let moon_phase = moon_phase_icons[Math.round(this.props.value * (moon_phase_icons.length-1))];

        if (moon_phase == "0") // no moon
            return <span style={{opacity: "10%"}}>{moon_phase}</span>;
        else
            return moon_phase;
    }

    render() {
        return (
            <React.Fragment>
                <div className={"info moon-phase-icon"}>
                    {this.moon_phase_icon()}
                </div>
            </React.Fragment>
        );
    }
}

export default MoonPhaseIcon
