import React from "react";

interface Props {
    icon: string,
}

export class WeatherIcon extends React.Component<Props, {}> {
    props: Props;

    render() {
        return (
            <React.Fragment>
                <div className={"weather-icon"}>
                    {this.props.icon}
                </div>
            </React.Fragment>
        );
    }
}

export default WeatherIcon
