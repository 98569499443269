import React from "react"

interface Props {
    value: number,
    max_value?: number,
    unit: string,
    display_unit?: string,
}

export class Speed extends React.Component<Props, {}> {
    props: Props;

    ms_to_mph(value: number) {
        return value * 2.23694;
    }

    ms_to_kmh(value: number) {
        return value * 3.6;
    }

    convert_speed(value: number) {
        switch (this.props.unit.toLowerCase()) {
            case "mph":
                return Math.round(this.ms_to_mph(value));
            case "kmh":
                return Math.round(this.ms_to_kmh(value));
            case "ms":
                return Math.round(value);
        }
    }

    unit_label() {
        switch (this.props.unit.toLowerCase()) {
            case "mph":
                return " mph";
            case "kmh":
                return " km/h";
            case "ms":
                return " m/s";
        }
    }

    formatted_unit() {
        switch (this.props.display_unit) {
            case "none":
                return <span/>;
            case "small":
                return <span style={{fontSize: "50%", verticalAlign: "30%"}}>{this.unit_label()}</span>;
            default:
            case "normal":
                return this.unit_label();
        }
    }

    formatted_value() {
        return <span>{this.convert_speed(this.props.value)}</span>;
    }

    formatted_max_value() {
        if (this.props.max_value == undefined || this.props.value == this.props.max_value)
            return <React.Fragment/>;

        return <span>-{this.convert_speed(this.props.max_value)}</span>;
    }

    formatted_speed() {
        return <span>{this.formatted_value()}{this.formatted_max_value()}{this.formatted_unit()}</span>;
    }

    render() {
        return (
            <React.Fragment>
                <div className={"info info-speed"}>
                    {this.formatted_speed()}
                </div>
            </React.Fragment>
        );
    }
}

export default Speed
