import React from "react"

interface Props {
    value: number,
}

const Directions: Array<string> = [
    "N", "NNE", "NE", "ENE",
    "E", "ESE", "SE", "SSE",
    "S", "SSW", "SW", "WSW",
    "W", "WNW", "NW", "NNW",
]

export class CompassDirection extends React.Component<Props, {}> {
    props: Props;

    formatted_direction() {
        return Directions[Math.round(((this.props.value / 11.25) + 1) / 2) % 16]
    }

    render() {
        return (
            <React.Fragment>
                <div className={"info info-direction"}>
                    {this.formatted_direction()}
                </div>
            </React.Fragment>
        );
    }
}

export default CompassDirection
