import React from "react"
import Cell from "./Cell";
import ParticulateMatter from "./ParticulateMatter";
import {AirQualityData} from "./Panel";

interface Props {
    air_quality: AirQualityData,
}

export type Breakpoint = {
    level: number,
    min: number,
    max: number,
}

export type Descriptor = {
    description: string,
    color: string,
}

interface AirQualityBreakpointList {
    [index: string]: Breakpoint[];
}

const AirQualityDescriptors: Array<Descriptor> = [
    { description: "Good",                            color: "rgb(0, 228, 0)" },
    { description: "Moderate",                        color: "rgb(255, 255, 0)" },
    { description: "Unhealthy for Sensitive Groups",  color: "rgb(255, 126, 0)" },
    { description: "Unhealthy",                       color: "rgb(255, 0, 0)" },
    { description: "Very Unhealthy",                  color: "rgb(143, 63, 151)" },
    { description: "Hazardous",                       color: "rgb(126, 0, 35)" },
    { description: "Apocalyptic",                     color: "rgb(255, 0, 255)" },
]

const UnknownAirQuality: Descriptor = { description: "Unknown", color: "rgb(255, 255, 255)" }

const AirQualityBreakpoints: AirQualityBreakpointList = {
    "1": [
        { level: 0, min:   0.0, max:  12.1 },
        { level: 1, min:  12.1, max:  35.5 },
        { level: 2, min:  35.5, max:  55.5 },
        { level: 3, min:  55.5, max: 150.5 },
        { level: 4, min: 150.5, max: 250.5 },
        { level: 5, min: 250.5, max: 500.0 },
        { level: 6, min: 500.0, max: +Infinity },
    ],
    "2.5": [
        { level: 0, min:   0.0, max:  12.1 },
        { level: 1, min:  12.1, max:  35.5 },
        { level: 2, min:  35.5, max:  55.5 },
        { level: 3, min:  55.5, max: 150.5 },
        { level: 4, min: 150.5, max: 250.5 },
        { level: 5, min: 250.5, max: 500.0 },
        { level: 6, min: 500.0, max: +Infinity },
    ],
    "10": [
        { level: 0, min:   0, max:  55 },
        { level: 1, min:  55, max: 155 },
        { level: 2, min: 155, max: 255 },
        { level: 3, min: 255, max: 355 },
        { level: 4, min: 355, max: 425 },
        { level: 5, min: 425, max: 605 },
        { level: 6, min: 605, max: +Infinity },
    ],
}

export function air_quality(pm_size: string, value: number): Descriptor {
    if (!AirQualityBreakpoints[pm_size])
        return UnknownAirQuality;

    for (const element of AirQualityBreakpoints[pm_size]) {
        if (value >= element.min && value < element.max)
            return AirQualityDescriptors[element.level];
    }

    return UnknownAirQuality;
}

export class AirQuality extends React.Component<Props, {}> {
    props: Props;

    render() {
        return (
            <React.Fragment>
                <table style={{width: "100%"}}>
                    <tbody>
                    <tr>
                        <td><ParticulateMatter value={this.props.air_quality.pm_1} pm_size={"1"} display_unit={"small"}/></td>
                        <td><ParticulateMatter value={this.props.air_quality.pm_2p5} pm_size={"2.5"} display_unit={"small"}/></td>
                        <td><ParticulateMatter value={this.props.air_quality.pm_10} pm_size={"10"} display_unit={"small"}/></td>
                    </tr>
                    <tr>
                        <td style={{fontSize: "50%"}}>24h ➔</td>
                        <td><ParticulateMatter value={this.props.air_quality.pm_2p5_last_24_hours} pm_size={"2.5"} display_unit={"small"}/></td>
                        <td><ParticulateMatter value={this.props.air_quality.pm_10_last_24_hours} pm_size={"10"} display_unit={"small"}/></td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default AirQuality
