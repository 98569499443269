import React from "react"

interface Props {
    value: number,
}

export class PressureTrend extends React.Component<Props, {}> {
    props: Props;

    render() {
        let x = 90 * -(this.props.value)
        if (this.props.value > 1)
            return <span style={{fontSize: "100%", display: "inline-block"}}>⇈</span>;
        else if (this.props.value < -1)
            return <span style={{fontSize: "100%", display: "inline-block"}}>⇊</span>;
        else
            return <span style={{fontSize: "80%", display: "inline-block", transform: "rotate(" + x + "deg)"}}>→</span>;
    }
}

export default PressureTrend
