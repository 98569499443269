import React from "react"

interface Props {
    value: number,
    unit: string,
    display_unit?: string,
}

export class Temperature extends React.Component<Props, {}> {
    props: Props;

    c_to_f(c) {
        return c * (9/5) + 32;
    }

    converted_temperature() {
        switch (this.props.unit.toLowerCase()) {
            case "f":
                return this.c_to_f(this.props.value);
            case "c":
                return this.props.value;
        }
    }

    unit_formatter() {
        switch (this.props.unit.toLowerCase()) {
            case "f":
                return new Intl.NumberFormat("en", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            case "c":
                return new Intl.NumberFormat("en", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                });
        }
    }

    unit_label() {
        switch (this.props.unit.toLowerCase()) {
            case "f":
                return "°F";
            case "c":
                return "°C";
        }
    }

    formatted_temperature() {
        return (
            <span>{this.unit_formatter().format(this.converted_temperature())}</span>
        );
    }

    formatted_unit() {
        switch (this.props.display_unit) {
            case "none":
                return <span/>;
            case "symbol":
                return <span>°</span>;
            case "small":
                return <span style={{fontSize: "50%", verticalAlign: "top"}}>{this.unit_label()}</span>;
            default:
            case "normal":
                return <span>{this.unit_label()}</span>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={"info info-temperature"}>
                    <span>{this.formatted_temperature()}{this.formatted_unit()}</span>
                </div>
            </React.Fragment>
        );
    }
}

export default Temperature
