import React from "react"
import {AlertsData, PanelData} from "./Panel";
import Alert from "./Alert";

interface Props {
    panel: PanelData,
    alerts: AlertsData,
}

export class AlertsList extends React.Component<Props, {}> {
    props: Props;

    render() {
        const total = this.props.alerts.alerts.length;

        return (
            <React.Fragment>
                <div style={{paddingBottom: "50px"}}>
                    {
                        this.props.alerts.alerts.map((alert, index) => {
                            return <Alert key={index} panel={this.props.panel} alert={alert} index={index} total={total}/>
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default AlertsList
