import React from "react"
import {ArrowLeft, CaretLeftFill, ChevronLeft} from "react-bootstrap-icons";

interface Props {
    changePage: (name: any, args: any) => void,
    children: any,
}

export class NavigablePage extends React.Component<Props, {}> {
    props: Props;

    render() {
        return (
            <React.Fragment>
                <table className="info" style={{height: '95%'}}>
                    <tbody>
                        <tr className="navigable-page">
                            <td width={"10%"} style={{textAlign: "center"}} onClick={() => this.props.changePage("default", null)}>
                                <span style={{fontSize: "500%"}}><CaretLeftFill/></span>
                            </td>
                            <td className="info" width="80%">
                                {this.props.children}
                            </td>
                            <td width={"10%"} style={{textAlign: "center"}}>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default NavigablePage
