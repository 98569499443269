import React from "react"
import {AlertsData, ConditionsData, ForecastData, LiveWeatherData, Page, PanelData} from "./Panel";
import OverlayPage from "./OverlayPage";
import NoDataPage from "./NoDataPage";
import DefaultPage from "./DefaultPage";
import AreaForecastDiscussionPage from "./AreaForecastDiscussionPage";
import AlertsPage from "./AlertsPage";

interface Props {
    page: Page,
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    alerts: AlertsData,
    live_weather: LiveWeatherData,
}

export class PanelContent extends React.Component<Props, {}> {
    props: Props;

    constructor(props) {
        super(props);

        let params = new URLSearchParams(document.location.search.substring(1));
        if (params.has("page")) {
            props.page.name = params.get("page");
        }
    }

    render() {
        if (!this.props.panel || (!this.props.conditions && !this.props.forecast))
            return <NoDataPage/>;

        if (this.props.page.name == "afd")
            return <AreaForecastDiscussionPage changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} live_weather={this.props.live_weather}/>;

        if (this.props.page.name == "alerts")
            return <AlertsPage changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} alerts={this.props.alerts} live_weather={this.props.live_weather}/>;

        if (this.props.page.name == "overlay")
            return <OverlayPage changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} live_weather={this.props.live_weather}/>;

        return <DefaultPage changePage={this.props.changePage} panel={this.props.panel} conditions={this.props.conditions} forecast={this.props.forecast} alerts={this.props.alerts} live_weather={this.props.live_weather}/>;
    }
}

export default PanelContent
