import React from "react"

interface Props {
    children: any;
    size: string,
    label: string,
}

export class Cell extends React.Component<Props, {}> {
    props: Props;

    render() {
        return (
            <React.Fragment>
                <div className={"info"}>
                    <div className={"info-" + this.props.size}>
                        {this.props.children}
                    </div>
                    <div className="info-label">{this.props.label}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default Cell
