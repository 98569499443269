import {ConditionsData, ForecastData, LiveWeatherData, PanelData} from "./Panel";
import React from "react";
import Cell from "./Cell";
import Temperature from "./Temperature";
import CompassDirection from "./CompassDirection";
import Speed from "./Speed";
import Pressure from "./Pressure";
import AirQuality from "./AirQuality";

interface Props {
    changePage: (name: any, args: any) => void,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    live_weather: LiveWeatherData,
}

class OverlayPage extends React.Component<Props, {}> {
    props: Props;

    live_weather_stale() {
        return (Date.now() - Date.parse(this.props.live_weather.time)) > 60000;
    }

    render_temperature() {
        return (
            <Cell size={"m"} label={""}>
                <Temperature value={this.props.live_weather.temperature} unit={this.props.panel.units.temperature}/>
            </Cell>
        );
    }

    render_wind_direction() {
        return (
            <Cell size={"m"} label={""}>
                <CompassDirection value={this.props.live_weather.wind_direction}/>
            </Cell>
        );
    }

    render_wind_speed() {
        return (
            <Cell size={"m"} label={""}>
                <Speed value={this.props.live_weather.wind_speed} max_value={this.props.live_weather.wind_speed_10_minutes_max} unit={this.props.panel.units.speed} display_unit={"small"}/>
            </Cell>
        );
    }

    render_pressure() {
        return (
            <Cell size={"m"} label={""}>
                <Pressure value={this.props.live_weather.pressure} trend={this.props.live_weather.pressure_trend} unit={this.props.panel.units.pressure} display_unit={"small"}/>
            </Cell>
        );
    }

    render_air_quality() {
        return (
            <Cell size={"s"} label={""}>
                <AirQuality air_quality={this.props.live_weather.air_quality}/>
            </Cell>
        )
    }

    render() {
        if (this.props.live_weather == null || this.live_weather_stale())
            return <React.Fragment/>;

        return (
            <React.Fragment>
                <table className="info" style={{height: '100%', textShadow: "4px 4px #000000"}}>
                    <tbody>
                    <tr className="info" style={{height: "90%"}}>
                        <td/>
                    </tr>
                    <tr className="info" style={{height: "10%"}}>
                        <td className="info" style={{width: "20%"}}>
                            {this.render_temperature()}
                        </td>
                        <td className="info" style={{width: "20%"}}>
                            {this.render_wind_speed()}
                        </td>
                        <td className="info" style={{width: "20%"}}>
                            {this.render_wind_direction()}
                        </td>
                        <td className="info" style={{width: "20%"}}>
                            {this.render_pressure()}
                        </td>
                        <td className="info" style={{width: "20%"}}>
                            {this.render_air_quality()}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default OverlayPage
