import React from "react"
import Time from "./Time";
import {AlertsData, PanelData} from "./Panel";

interface Props {
    panel: PanelData,
    alerts: AlertsData,
}

export class AlertsIndex extends React.Component<Props, {}> {
    props: Props;

    render() {
        const total = this.props.alerts.alerts.length;

        return (
            <React.Fragment>
                <div id={"alerts-index"} className={"pt-4"} style={{paddingBottom: "50px"}}>
                    <ul style={{listStyle: "none"}}>
                        {
                            this.props.alerts.alerts.map((alert, index) => {
                                return (
                                    <li key={index} style={{fontSize: "1.5vw"}} className={"pe-2"}>
                                        <a href={"#alert-" + index}>
                                            {index+1} of {total} – {alert.severity} – {alert.event} – <Time format={"%F %T"} zone={this.props.panel.times.zone} value={alert.onset}/>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default AlertsIndex
