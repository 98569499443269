import React from "react"
import PressureTrend from "./PressureTrend";

interface Props {
    value: number,
    trend: number,
    unit: string,
    display_unit?: string,
}

export class Pressure extends React.Component<Props, {}> {
    props: Props;

    hpa_to_inhg(value) {
        return value / 33.8638866667;
    }

    hpa_to_kpa(value) {
        return value / 10;
    }

    converted_pressure() {
        switch (this.props.unit.toLowerCase()) {
            case "inhg":
                return this.hpa_to_inhg(this.props.value);
            case "kpa":
                return this.hpa_to_kpa(this.props.value);
            case "hpa":
                return this.props.value;
        }
    }

    unit_formatter() {
        switch (this.props.unit.toLowerCase()) {
            case "inhg":
                return new Intl.NumberFormat("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            case "kpa":
                return new Intl.NumberFormat("en", {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                });
            case "hpa":
                return new Intl.NumberFormat("en", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
        }
    }

    unit_label() {
        switch (this.props.unit.toLowerCase()) {
            case "inhg":
                return <span>"</span>;
            case "kpa":
                return <span>&nbsp;kPa</span>;
            case "hpa":
                return <span>&nbsp;hPa</span>;
        }
    }

    formatted_unit() {
        switch (this.props.display_unit) {
            case "none":
                return <span/>;
            case "small":
                if (this.props.unit == "inhg")
                    return <span>{this.unit_label()}</span>;
                else
                    return <span style={{fontSize: "50%", verticalAlign: "30%"}}>{this.unit_label()}</span>;
            default:
            case "normal":
                return this.unit_label();
        }
    }

    formatted_pressure() {
        return <span>{this.unit_formatter().format(this.converted_pressure())}{this.formatted_unit()}</span>;
    }

    render() {
        if (this.props.trend != null) {
            return (
                <React.Fragment>
                    <div className={"info info-pressure"}>
                        <span style={{paddingRight: "5px"}}>{this.formatted_pressure()}</span>
                        <PressureTrend value={this.props.trend}/>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={"info info-pressure"}>
                        {this.formatted_pressure()}
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default Pressure
