import React from "react"
import PanelChannel from "./PanelChannel";
import PanelContent from "./PanelContent";
import Channels from "./Channels";

interface Props {
    slug: string,
}

export type Page = {
    name: string;
    args: object;
}

export type Units = {
    temperature: string,
    pressure: string,
    speed: string,
}

export type Times = {
    zone: string,
    format: string,
}

export type PanelData = {
    time: string,
    name: string,
    location: string,
    units: Units,
    times: Times,
}

export type ConditionsData = {
    time: string,
    temperature: number,
    humidity: number,
    wind_direction: number,
    wind_speed: number,
    pressure: number,
    sunrise: string,
    sunset: string,
    description: string,
    meteocons_icon: string,
}

export type DailyForecastData = {
    time: string,
    meteocons_icon: string,
    temperature_min: number,
    temperature_max: number,
    moon_phase: number,
}

export type ForecastData = {
    time_min: string,
    time_max: string,
    summary: string,
    afd: string,
    daily_forecast: Array<DailyForecastData>,
}

export type AlertData = {
    sent: string,
    effective: string,
    onset: string,
    expires: string,
    ends: string,
    status: string,
    severity: string,
    certainty: string,
    urgency: string,
    event: string,
    headline: string,
    description: string,
    instruction: string,
}

export type AlertsData = {
    time: string,
    alerts: Array<AlertData>,
}

export type AirQualityData = {
    pm_1: number,
    pm_2p5: number,
    pm_2p5_last_1_hour: number,
    pm_2p5_last_24_hours: number,
    pm_10: number,
    pm_10_last_24_hours: number,
    pm_10_last_1_hour: number,
}

export type LiveWeatherData = {
    time: string,
    temperature: number,
    humidity: number,
    dew_point: number,
    wind_direction: number,
    wind_speed: number,
    wind_speed_10_minutes_max: number,
    pressure: number,
    pressure_trend: number,
    solar_radiation: number,
    uv_index: number,
    air_quality: AirQualityData,
    indoor_temperature: number,
    indoor_humidity: number,
    indoor_air_quality: AirQualityData,
}

type State = {
    active_page: Page,
    panel: PanelData,
    conditions: ConditionsData,
    forecast: ForecastData,
    alerts: AlertsData,
    live_weather: LiveWeatherData,
}

export class Panel extends React.Component<Props, State> {
    props: Props;
    state: State;

    constructor(props: Props, state: State) {
        super(props, state);

        this.state = {
            active_page: { name: "default", args: null },
            panel: null,
            conditions: null,
            forecast: null,
            alerts: null,
            live_weather: null,
        }
    }

    receivePanelChannel(response) {
        if (response.reload_required) {
            location.reload();
            return;
        }

        this.setState({...response})
    }

    render () {
        let changePage = (name, args) => {
            this.setState({ active_page: { name: name, args: args } });
        }

        return (
            <React.Fragment>
                <Channels>
                    <PanelChannel slug={this.props.slug} onReceived={response => this.receivePanelChannel(response)}/>
                </Channels>
                <PanelContent page={this.state.active_page} changePage={changePage} panel={this.state.panel} conditions={this.state.conditions} forecast={this.state.forecast} alerts={this.state.alerts} live_weather={this.state.live_weather}/>
            </React.Fragment>
        );
    }
}

export default Panel
